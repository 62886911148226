<div class="trash-settings-wrapper">
  <vnc-header title="{{ 'TRASH_SETTINGS' | translate }}"
              type="title-close"
              size="l"
              [showCloseIcon]="true"
              (onCloseClick)="closeSettings()">
  </vnc-header>
  <div class="settings">
    <div class="setting-name">{{ 'AUTO_DASH_CLEAN' | translate | uppercase }}</div>
    <div class="toggle-menu">
      <div class="left-part">
        <div class="setting-title">{{ 'AUTO_CLEAN_TRASH_FOLDER' | translate }}</div>
        <div class="setting-description">{{ 'AUTOMATICALLY_REMOVE_ALL_THE_ITEMS' | translate }}</div>
      </div>
      <div class="right-part">
        <div class="kebab-menu">
<!--          <ng-container [ngTemplateOutlet]="kebabMenuIconTemplate"></ng-container>-->
        </div>
        <div class="toggle-input">
          <vnc-toggle-switch [size]="'s'" [checked]="enableAutoClean" (changeEvent)="enableAutoClean = !enableAutoClean"></vnc-toggle-switch>
        </div>
      </div>
    </div>
    <div class="period-selector">
      <div class="interval-select">
        <mat-form-field [ngClass]="{'disable-dropwdown': !enableAutoClean}">
          <mat-label class="option-label">{{ 'PERIOD' | translate }}</mat-label>
          <mat-select id="intervalSelection" [formControl]="intervalControl">
            <mat-option [attr.id]="interval.label" *ngFor="let interval of intervals"
                        [value]="interval.value">{{ interval.label | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <vnc-footer [secondaryLabel]="'CANCEL' | translate | uppercase"
              [primaryLabel]="'SAVE' | translate | uppercase"
              [type]="'secondary-primary'"
              [color]="'white'"
              [isPrimaryDisabled]="isLoading"
              (onPrimaryClick)="onSave()"
              (onSecondaryClick)="closeSettings()"></vnc-footer>
</div>

<ng-template #kebabMenuIconTemplate>
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18">
    <g fill="none" fill-rule="evenodd">
      <g fill="#8B96A0">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path d="M1.5 3C2.325 3 3 2.325 3 1.5S2.325 0 1.5 0 0 .675 0 1.5.675 3 1.5 3zm0 1.5C.675 4.5 0 5.175 0 6s.675 1.5 1.5 1.5S3 6.825 3 6s-.675-1.5-1.5-1.5zm0 4.5C.675 9 0 9.675 0 10.5S.675 12 1.5 12 3 11.325 3 10.5 2.325 9 1.5 9z" transform="translate(-1799.000000, -243.000000) translate(1416.000000, 116.000000) translate(16.000000, 104.000000) translate(364.000000, 20.000000) translate(3.000000, 3.000000) translate(7.500000, 3.000000)"/>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</ng-template>
